import React from 'react'
import { Box, Flex, Link as Anchor } from 'rebass/styled-components'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import ContentWrapper from 'components/ContentWrapper'
import Logo from 'components/Logo'
const ICON_SIZE = '2em'
const MenuIcon = styled.div`
  background-image: url('/static/icon/menu.svg');
  cursor: pointer;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.15s ease-in;
  ${(props) =>
    props.$visible &&
    css`
      opacity: 1;
    `};
`

const CloseIcon = styled.div`
  background-image: url('/static/icon/close.svg');
  cursor: pointer;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.15s ease-in;
  ${(props) =>
    props.$visible &&
    css`
      opacity: 1;
    `};
`

const Absolute = styled(Box)`
  position: absolute;
`

const Fixed = styled(Box)`
  position: fixed;
`

const StyledHeader = styled(Flex)`
  min-height: 5em;
  transform: ${(props) => (props.open ? 'translateY(3em)' : 'translateY(1em)')};
  transition: transform 0.35s ease;
`

const StyledLink = styled.span`
  &,
  span {
    display: block;
    font-family: ${(props) => props.theme.fonts.sans};
    margin-bottom: 0.5em;
    text-decoration: none;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors.white};
    transition: color 0.2s ease-in;
    &:hover {
      color: ${(props) => props.theme.colors.accent2};
    }
  }
`

const MenuItemsWrapper = styled(Box)`
  height: ${(props) => (props.open ? '50vh' : '0')};
  min-height: ${(props) => (props.open ? '440px' : '0')};
  transition: height 0.1s linear;
  overflow: hidden;
  text-align: right;

  & ${StyledLink} {
    font-size: ${(props) => props.theme.fontSizes[5]}px;
  }
`

const MobileMenuItemsWrapper = styled(Box)`
  transition: height 0.1s linear;
  overflow: hidden;
  text-align: right;
  height: ${(props) => (props.open ? '100vh' : '0')};

  & ${StyledLink} {
    font-size: ${(props) => props.theme.fontSizes[4]}px;
  }
`
const MobileContentWrapper = styled(Box)`
  display: none;
  @media only screen and (max-width: 48em),
    (orientation: landscape) and (max-width: 48em) {
    display: block;
    background-color: ${(props) => props.theme.colors.primary} !important;
  }
`

const NormalContentWrapper = styled(Box)`
  display: none;
  @media (min-width: 48.001em) and (min-height: 360px) {
    display: block;
  }
`

const CloseBox = styled(Absolute)`
  height: 100vh;
  width: 100vw;
`
const menu = [
  {
    text: 'Home',
    url: '/',
  },
  {
    text: 'About',
    url: '/about',
  },
  {
    text: 'Our Work',
    url: '/work',
  },
  // {
  //   text: 'Approach',
  //   url: '/approach',
  // },
  {
    text: 'Blog',
    url: '/blog',
  },
  // {
  //   text: 'Contact',
  //   url: '/contact',
  // },
]

function Header () {
  const [open, setOpen] = React.useState(false)
  const toggle = () => setOpen(!open)

  const menuItems = menu.map((item) => (
      <Link key={item.url} href={item.url} passHref>
        <StyledLink>
          <span onClick={() => setOpen(!open)}>
            {item.text}
          </span>
        </StyledLink>
      </Link>
  ))

  return (
      <Fixed width={1} top='0' left='0' right='0' style={{ zIndex: 11 }}>
        <NormalContentWrapper>
        <ContentWrapper $background $noPadding>
          <StyledHeader px={3} open={open}>
            <Box width={['20%', '10%']}>
              <Flex>
                <Absolute>

                    <Logo key='logo-closed' $visible={!open} />

                </Absolute>
                <Absolute>
                  <Logo key='logo-open' icon $visible={open} />
                </Absolute>
              </Flex>
            </Box>
            <MenuItemsWrapper width={['60%', '80%']} open={open}>
              {menuItems}
            </MenuItemsWrapper>
            <Box width={['20%', '10%']} onClick={toggle}>
              <Flex justifyContent='flex-end'>
                <Absolute>
                  <MenuIcon $visible={!open} />
                </Absolute>
                <Absolute>
                  <CloseIcon $visible={open} />
                </Absolute>
              </Flex>
            </Box>
          </StyledHeader>
        </ContentWrapper>
        </NormalContentWrapper>
        {open && <CloseBox onClick={() => setOpen(false)} />}
        <MobileContentWrapper>
        <ContentWrapper $background $noPadding>

          <StyledHeader px={3} open={open}>
            <Box width={!open ? '20%' : '0%'}>
              <Flex>
                <Absolute mt={1}>

                    <Logo key='logo-closed'  $visible={!open} />

                </Absolute>
              </Flex>
            </Box>
            <MobileMenuItemsWrapper width={'80%'} open={open}>
              {menuItems}
            </MobileMenuItemsWrapper>
            <Box width={'20%'} onClick={toggle}>
              <Flex justifyContent='flex-end'>
                <Absolute>
                  <MenuIcon $visible={!open} />
                </Absolute>
                <Absolute>
                  <CloseIcon $visible={open} />
                </Absolute>
              </Flex>
            </Box>
          </StyledHeader>
          </ContentWrapper>
        </MobileContentWrapper>
      </Fixed>
  )
}



export default Header
