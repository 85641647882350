import React from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import Footer from 'components/Footer'
import Header from 'components/Header'
import {
  PRIMARY_COLOR,
  ACCENT1_COLOR,
  ACCENT2_COLOR,
  GRAY1_COLOR,
  GRAY2_COLOR,
  GRAY3_COLOR,
  WHITE_COLOR,
  SITE_GUTTER,
  FONT_SANS,
  FONT_SERIF,
} from '../services/constants'

const GlobalStyle = createGlobalStyle`
  * { box-sizing: border-box; }
  body { margin: 0; }
  html, body { width: 100%; }
  p {
    font-family: ${FONT_SERIF}
    font-size: 1.4rem;
  }
`

const customTheme = {
  fonts: {
    sans: FONT_SANS,
    body: FONT_SANS,
    heading: FONT_SANS,
    serif: FONT_SERIF,
  },
  breakpoints: ['40em', '52em', '64em'],
  font: FONT_SANS,
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: [
    300, // light, subheads
    400, // normal, body
    500, // medium, headlines
  ],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  letterSpacing: '0.05em',
  colors: {
    black: PRIMARY_COLOR,
    primary: PRIMARY_COLOR,
    accent1: ACCENT1_COLOR,
    accent2: ACCENT2_COLOR,
    gray1: GRAY1_COLOR,
    gray2: GRAY2_COLOR,
    gray3: GRAY3_COLOR,
    white: WHITE_COLOR,
  },
}

const getGutter = () => `${SITE_GUTTER.toString()}%`

// Our gray sidebars
const Gutter = styled.div`
  position: fixed;
  left: ${(props) => (props.left ? '0' : getGutter())};
  top: 0;
  bottom: 0;
  right: ${(props) => (props.left ? getGutter() : '0')};
  background-color: #efefef;
  z-index: -1;

  @media (max-width: 32em) {
    display: none;
  }
`

// Prevents overlaid collision with header
const OffsetContainer = styled.main`
  position: relative;
  top: 5em;
`
// Use this component to wrap all pages. This sets up global styles,
// themes and potentially redux store
const Layout = ({ children }) => {

    return (
      <ThemeProvider theme={customTheme}>
        <GlobalStyle />
        <Header />
        <Gutter left />
        <Gutter />
        <OffsetContainer>
          {children}
          <Footer />
        </OffsetContainer>
      </ThemeProvider>
    )

}

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout
