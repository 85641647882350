import { transparentize } from 'polished'
export const PRIMARY_COLOR = '#363f48'
export const ACCENT1_COLOR = '#e95a4f'
export const ACCENT2_COLOR = '#f48b7f'
export const ACCENT3_COLOR = transparentize(0.5, ACCENT1_COLOR)
export const GRAY1_COLOR = '#808080'
export const GRAY2_COLOR = '#c3c3c3'
export const GRAY3_COLOR = '#efefef'
export const WHITE_COLOR = '#ffffff'
export const FONT_WEIGHTS = [200, 400, 500]
export const SITE_GUTTER = 87.5
// Enable for live Wordpress
// export const API = 'https://admin.fountstudio.com/wp-json/wp/v2'
// export const API = 'http://localhost:3000/api/wp-json/wp/v2'
console.log('The VERCEL_URL ', process.env.NEXT_PUBLIC_VERCEL_URL)
export const API = `/api/wp-json/wp/v2`
export const CONTACT_FORM_URL =
  'https://gvi1l7vqc4.execute-api.us-east-1.amazonaws.com/production/submit'
export const FONT_SANS =
  '"Jost", -apple-system, BlinkMacSystemFont, Roboto, sans-serif'
export const FONT_SERIF = 'Baskerville, Times New Roman, Times, serif'
