import styled from 'styled-components'
import { transparentize, darken } from 'polished'
import { Box } from 'rebass/styled-components'
import PropTypes from 'prop-types'

const StyledWrapper = styled(Box)`
  background-color: ${(props) =>
    darken(0.1, transparentize(0.1, props.theme.colors.primary))};
`

const BackgroundWrapper = (props) => (
  <StyledWrapper>{props.children}</StyledWrapper>
)

BackgroundWrapper.propTypes = {
  children: PropTypes.any,
}

export default BackgroundWrapper
