import {
  Box,
  Flex,
  Image,
  Heading,
  Link as Anchor,
} from 'rebass/styled-components'
import Link from 'next/link'
import styled from 'styled-components'
import BackgroundWrapper from 'components/BackgroundWrapper'
import PropTypes from 'prop-types'

const StyledText = styled(Heading)`
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.fontWeights[2]};
  text-align: center;
  letter-spacing: 0.08em;
`
const IconImage = styled(Image)`
  width: 24px;
  height: 24px;
  display: inline;
  margin-inline: ${props => props.theme.space[2]}px;
`

const StyledLink = styled.span`
  cursor: pointer;
`

StyledLink.propTypes = {
  className: PropTypes.string,
}

const Footer = () => (
  <BackgroundWrapper>
    <Flex alignItems='center' justifyContent='center' is='footer'>
      <Box width={1} p={6}>
        <Flex width={1} my={2} justifyContent='center'>
          <a
            title='@fount'
            href='https://twitter.com/fount'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconImage

              src='/static/icon/twitter.svg'
              alt='Twitter icon'
            />
          </a>
          <a
            href='https://www.linkedin.com/company/fount'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconImage

              src='/static/icon/linkedin.svg'
              alt='LinkedIn icon'
            />
          </a>
          <Link href='/contact' passHref>
            <StyledLink title='hi@fountstudio.com'>
              <IconImage

                src='/static/icon/envelope.svg'
                alt='Email icon'
              />
            </StyledLink>
          </Link>
        </Flex>
        {/* NOTE: We might show this on certain pages */}
        {/* <StyledText fontSize={1} mb={1}>Proudly based in Charleston, South Carolina</StyledText> */}
        <StyledText fontSize={1} mb={1}>
          © {new Date().getFullYear()} FOUNT
        </StyledText>
      </Box>
    </Flex>
  </BackgroundWrapper>
)

export default Footer
