import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'
import { transparentize } from 'polished'
import { SITE_GUTTER } from '../services/constants'

const getContentPadding = () => `${100 - SITE_GUTTER}%`

// Styled component definition
const StyledContentWrapper = styled(Box)`
  padding-left: ${getContentPadding()};
  padding-right: ${getContentPadding()};
  padding-top: ${(props) => (props.$noPadding ? '0' : props.theme.space[4])}px;
  padding-bottom: ${(props) =>
    props.$noPadding ? '0' : props.theme.space[4]}px;
  min-height: ${(props) => (props.$fullHeight ? '100vh' : 'auto')};
  background-color: ${(props) => {
    if (props.$background) {
      return transparentize(
        props.$noOpacity ? 0 : 0.025,
        props.theme.colors.primary,
      )
    }
    if (props.$accent) {
      return transparentize(
        props.$noOpacity ? 0 : 0.025,
        props.theme.colors.accent1,
      )
    }
    return 'transparent'
  }};
  @media (max-width: 32em) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`

// Functional component
const ContentWrapper = ({
  $accent = false,
  $noPadding = false,
  $background = false,
  $fullHeight = false,
  theme,
  children,
}) => (
  <StyledContentWrapper
    theme={theme}
    $background={$background}
    $noPadding={$noPadding}
    $fullHeight={$fullHeight}
    $accent={$accent}
  >
    {children}
  </StyledContentWrapper>
)

ContentWrapper.propTypes = {
  $background: PropTypes.bool,
  theme: PropTypes.shape({}),
  children: PropTypes.any,
  $noPadding: PropTypes.bool,
  $fullHeight: PropTypes.bool,
  $accent: PropTypes.bool,
}

export default ContentWrapper
