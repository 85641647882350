import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import anime from 'animejs';
import LogoSvg from '../public/static/icon/fountlogo.svg';
import IconSvg from '../public/static/icon/founticon.svg';

const [IconLogo, IconOnly] = [LogoSvg, IconSvg].map(
    (svg) => styled(svg)`
      height: auto;
      width: ${(props) => props.$size?.toString()};
      opacity: ${(props) => (props.$visible === true ? '1.0' : '0')};
      transition: opacity 0.35s ease-in;

      path,
      g {
        fill: ${(props) => (props.color ? props.color : '#FFFFFF')};
      }
    `
);

const GrowIcon = styled.svg`
  height: auto;
  width: ${(props) => props.$size?.toString()};
  opacity: 0;
`;

const LoadingIcon = styled.svg`
  height: auto;
  width: ${(props) => props.$size?.toString()};
`;

const Logo = ({ $visible = true, icon = false, $size = 'auto', color, grow = false, loading = false }) => {
  const animatedLogoRef = useRef(false);

  const growLogo = () => {
    animatedLogoRef.current = true;
    anime.timeline()
        .add({
          targets: '.icon-svg',
          opacity: 1,
        })
        .add({
          targets: '.base',
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 700,
          delay: 15,
          easing: 'easeOutQuart',
        })
        .add({
          targets: '.left',
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 600,
          delay: 25,
          easing: 'easeOutQuart',
        })
        .add({
          targets: '.middle',
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 600,
          easing: 'easeOutQuart',
        })
        .add({
          targets: '.right',
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 600,
          delay: 100,
          easing: 'easeOutQuart',
        });
  };

  const animateLoadingLogo = () => {
    animatedLogoRef.current = true;

    const leftBarAnimation = () => {
      anime({
        targets: '.left',
        strokeDashoffset: [anime.random(30, 38), 0],
        duration: 700,
        easing: 'easeOutSine',
        direction: 'alternate',
        complete: () => leftBarAnimation(),
      });
    };

    const middleBarAnimation = async (left, right, start = anime.random(30, 38)) => {
      const end = anime.random(30, 38);
      if (left) {
        anime.timeline({ autoplay: true })
            .add({
              targets: '.middle-right',
              opacity: 0,
            })
            .add({
              targets: '.middle-left',
              opacity: 1,
            })
            .add({
              targets: '.middle-left',
              strokeDashoffset: [start, 0],
              duration: 800,
              easing: 'easeOutSine',
            })
            .add({
              targets: '.middle-left',
              strokeDashoffset: [0, end],
              duration: 800,
              easing: 'easeOutSine',
              complete: () => middleBarAnimation(false, true, end),
            });
      } else if (right) {
        anime.timeline({ autoplay: true })
            .add({
              targets: '.middle-left',
              opacity: 0,
            })
            .add({
              targets: '.middle-right',
              opacity: 1,
            })
            .add({
              targets: '.middle-right',
              strokeDashoffset: [start, 0],
              duration: 800,
              easing: 'easeOutSine',
            })
            .add({
              targets: '.middle-right',
              strokeDashoffset: [0, end],
              duration: 800,
              easing: 'easeOutSine',
              complete: () => middleBarAnimation(true, false, end),
            });
      }
    };

    const rightBarAnimation = () => {
      anime({
        targets: '.right',
        strokeDashoffset: [anime.random(30, 38), 0],
        duration: 600,
        easing: 'easeOutSine',
        direction: 'alternate',
        complete: () => rightBarAnimation(),
      });
    };

    leftBarAnimation();
    middleBarAnimation(true, false);
    rightBarAnimation();
  };

  useEffect(() => {
    return () => {
      animatedLogoRef.current = false;
    };
  }, []);

  if (loading) {
    return (
        <span>
        <Waypoint onEnter={() => !animatedLogoRef.current && animateLoadingLogo()} />
        <LoadingIcon
            size={$size}
            className="icon-svg"
            width="36.621"
            height="44.816"
            version="1.1"
            viewBox="0.2 0 36.620721 44.816758"
        >
          <g
              transform='translate(-47.017 -1.7983)'
              fill='none'
              fillRule='evenodd'
              stroke='#fff'
              strokeWidth='3.0001'
          >
              <path
                  className='left'
                  d='m61.337 42.747v-29.678c0-3.1303-1.1851-5.4672-4.3017-5.4672-3.1167 0-4.0943 2.4406-4.0943 5.5709v2.6752'
                  strokeWidth='3.0001'
                  strokeDasharray='48'
              />
              <path
                  className='middle-left'
                  d='m65.63 42.742-0.10914-31.533c-0.01427-4.1244-3.8325-8.0032-8.6766-7.909-5.4763 0.10641-8.1272 4.6913-8.1272 9.4585v3.1143'
                  strokeWidth='3.0001'
                  strokeDasharray='61'
              />
              <path
                  className='middle-right'
                  d='m65.63 42.742-0.072485-27.982c0.63563-4.1257 3.9346-7.1739 8.7214-7.0754 5.434 0.094442 8.0644 4.1637 8.0644 8.3947v3.7028'
                  strokeWidth='3.0001'
                  strokeDasharray='57.067'
              />
              <path
                  className='right'
                  d='m69.741 42.747v-25.269c0-2.6653 1.0295-5.5884 4.1461-5.5884 3.5724 0 4.2498 3.0114 4.2498 5.6766v2.2778'
                  strokeWidth='3.0001'
                  strokeDasharray='43'
              />
              <path className='base' d='m56.358 45.112h18.085' />
            </g>
        </LoadingIcon>
      </span>
    );
  } else if (grow) {
    return (
        <span>
        <Waypoint onEnter={() => !animatedLogoRef.current && growLogo()} />
        <GrowIcon $size={$size} className="icon-svg" viewBox="0 0 36.419842 44.947991">
          <g
              transform='translate(-47.15 -1.664)'
              fill='none'
              stroke='#fff'
              strokeWidth='3'
              fillRule='evenodd'
          >
              <g strokeWidth='3'>
                <path
                    d='m61.27 42.608v-29.678c0-3.1303-1.1851-5.4672-4.3017-5.4672-3.1167 0-4.0943 2.4406-4.0943 5.5709v2.6752'
                    className='left'
                />
                <path
                    d='m65.563 42.608-0.10914-31.533c-0.01428-4.1244-3.8325-8.0032-8.6766-7.909-5.4763 0.10641-8.1272 4.6913-8.1272 9.4585v3.1143'
                    className='middle'
                />
                <path
                    d='m69.741 42.608v-25.272c0-2.6656 1.0295-5.589 4.1461-5.589 3.5724 0 4.2498 3.0117 4.2498 5.6772v2.278'
                    className='right'
                />
              </g>
              <path
                  d='m56.358 45.112h18.085'
                  className='base'
                  strokeWidth='3.0061'
              />
            </g>
        </GrowIcon>
      </span>
    );
  }

  return (
      <span>
      {icon ? (
          <IconOnly $visible={$visible} $size={$size} color={color} />
      ) : (
          <IconLogo $visible={$visible} $size={$size} color={color} />
      )}
    </span>
  );
};

Logo.propTypes = {
  $visible: PropTypes.bool,
  icon: PropTypes.bool,
  $size: PropTypes.any,
  color: PropTypes.string,
  loading: PropTypes.bool,
  grow: PropTypes.bool,
};

export default Logo;